<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group mt-3 p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="form-group icon-input mb-0">
                            <i class="ti-search font-xs text-grey-400"></i>
                            <input type="search" @keyup.enter="searchCourse" v-model="search" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" placeholder="Type here what you are looking for....">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <button @click="searchCourse" class="w-100 d-block btn-current rounded-lg font-xssss fw-600 ls-3 style1-input p-0 text-uppercase ">Search</button>
                    </div>
                </div>
            </div>
            <div class="row" v-if="courseExist">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4 p-auto" v-for="(item, index) in courses.data" :key="index">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <div class="card-image w-100 mb-3 overflow-hidden rounded-lg">
                            <router-link :to="`/my-course/${item.slug}`" class="position-relative d-block">
                            <img :src="item.banner ? item.banner : 'https://via.placeholder.com/400x300.png'" alt="image" class="w-100">
                            </router-link>
                        </div>
                        <div class="card-body">
                            <span class="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1">{{ item.category }} - {{ item.level }}</span>
                            <h4 class="fw-700 font-xss mt-3 lh-28 mt-0"><router-link :to="`/my-course/${item.slug}`" class="text-dark text-grey-900">{{item.name}}</router-link></h4>
                            <div class="row justify-content-between mt-3">
                                <h6 class="font-xssss text-grey-500 fw-600 col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-user-graduate mr-2"></i>{{item.total_student}} Students</h6>
                                <h6 class="font-xssss text-grey-500 fw-600 col-lg-6 col-md-6 col-sm-6 col-xs-6"><i class="text-current fas fa-copy mr-2"></i>{{item.total_material}} Materials</h6>
                            </div>
                            <hr>
                            <div class="row d-flex justify-content-between">
                                <router-link title="Announcement" :to="`/my-course/${item.slug}/announcements`" class="col"><i class="text-muted font-xs fas fa-bullhorn"></i><span class="icon-count bg-current right-none">{{item.total_announcement}}</span></router-link>
                                <router-link title="Assessment Task" :to="`/my-course/${item.slug}/assessment-task`" class="col"><i class="text-muted font-xs fas fa-clipboard-list"></i><span class="icon-count bg-current right-none">{{item.total_assignment}}</span></router-link>
                                <router-link title="MasterFeedback" :to="`/my-course/${item.slug}/feedback`" class="col"><i class="text-muted font-xs fas fa-comments"></i><span class="icon-count bg-current right-none">{{item.total_feedback}}</span></router-link>
                                <router-link title="Materials" :to="`/my-course/${item.slug}/materials`" class="col"><i class="text-muted font-xs fas fa-folder"></i><span class="icon-count bg-current right-none">{{item.total_material}}</span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 mb-4">
                    <ul class="pagination pagination-center justify-content-center d-flex pt-5">
                        <li v-for="(link, index) in courses.links" :key="index" class="page-item m-1">
                            <button v-if="link.label === '&laquo; Previous'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="prevCourse()"><i class="ti-angle-left"></i></button>
                            <button v-else-if="link.label === 'Next &raquo;'" v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" :disabled="link.url === null" @click="nextCourse()"><i class="ti-angle-right"></i></button>
                            <button v-else v-bind:class="{'bg-current' : link.active === true, 'bg-white' : link.active === false}" class="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-grey-900 border-1" @click="changeCourse(link.label)">{{link.label}}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                    <div class="my-3" v-show="loadCourse">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                    <img :src="'/images/empty.png'" v-show="courseNotExist" alt="" width="300">
                    <h1 class="text-muted" v-show="courseNotExist">Data don't exist</h1>
                </div>
            </div>
        </div>
    </div>            
</template>

<script>
import axios from 'axios';

export default {
    name: `MyCourse`,
    data(){
        return {
            user:{},
            courses: {},
            search:'',
            courseExist: false,
            courseNotExist: false,
            loadCourse: true,
            page: 1,
        }
    },
    created() {
        this.getCourses(this.page)
    },
    methods: {
       async getCourses(page) {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/mycourse?page=${parseInt(page)}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.data.length == 0) {
                    this.courseNotExist = true
                } else {
                    this.courseExist = true
                    this.courses = res.data
                }
                this.loadCourse = false
            }).catch((err) => {
                this.loadCourse = false
                if (err.response.status == 404) {
                    this.courseNotExist = true
                }
            })
        },
        changeCourse(page) {
            this.page = parseInt(page)
            this.getCourses(this.page)
        },
        prevCourse() {
            if (this.page >= 1) {
                this.page -= 1
                this.getCourses(this.page)
            }
        },
        nextCourse() {
            if (this.courses.last_page > this.page) {
                this.page += 1
                this.getCourses(this.page)
            }
        },
        searchCourse(){
            this.courseExist = false
            this.courseNotExist = false
            this.loadCourse = true
            this.page = 1
            this.getCourses(this.page)
        },
    },
    computed: {
        fullpath: function() {
            return this.courses.data.map(function(item) {
                return process.env.VUE_APP_URL_CLOUD + item.banner;
            });
        }
    }
}
</script>
<style scoped>
    .right-none {
        right: auto !important;
    }
</style>